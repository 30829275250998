import { NgClass } from "@angular/common";
import type { AfterViewInit, OnInit, Signal } from "@angular/core";
import {
   Component,
   computed,
   effect,
   inject,
   input,
   signal,
   ViewChild,
} from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
   CardComponent,
   IconComponent,
   isMobile,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   MinimalIconButtonComponent,
   ModalService,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import {
   ArcElement,
   CategoryScale,
   Chart,
   type ChartData,
   type ChartDataset,
   type ChartOptions,
   LinearScale,
   LineController,
   LineElement,
   PointElement,
   Tooltip,
   type TooltipModel,
} from "chart.js";
import moment from "moment";
import { AsyncSubject, combineLatest, finalize, switchMap } from "rxjs";
import type { Asset } from "src/app/assets/types/asset.types";
import type { WidgetDefinition } from "src/app/dashboards/custom-dashboards/customDashboard.types";
import { DashboardModalHelpersService } from "src/app/dashboards/custom-dashboards/dashboardModalHelpers.service";
import type { Filter, FilterType } from "src/app/dashboards/filters";
import { FilterSelectorComponent } from "src/app/dashboards/filters/filterSelector/filterSelector.component";
import { DashboardService } from "src/app/dashboards/global-dashboard/dashboard.service";
import { ChartJSHelper } from "src/app/dashboards/widgets/chartJSHelper";
import {
   type LineBarGraphDisplayHandler,
   LineBarGraphDisplayHandlerService,
} from "src/app/dashboards/widgets/line-graph/line-bar-graph-display-handler/line-bar-graph-display-handler.service";
import { getDefaultEnglishWidgetDef } from "src/app/dashboards/widgets/widget/default";
import type {
   LineBarGraphContent,
   ListBarGraphContentElement,
   TileContent,
   WidgetContent,
} from "src/app/dashboards/widgets/widget/widgetContent.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManagePO } from "src/app/purchasing/services/managePO";
import { ThemingService } from "src/app/settings/services/themingService";
import { RoiSettings } from "src/app/shared/components/global/insights-modal/roi-settings-modal/roi-settings-modal.component";
import { ViewListModal } from "src/app/shared/components/global/lists/viewList.modal.component";
import type { VirtualWidgetID } from "src/app/shared/components/global/virutalWidget/virtual-widget.models";
import { VirtualWidgetService } from "src/app/shared/components/global/virutalWidget/virtual-widget.service";
import {
   type DataViewerFilter,
   DataViewerFiltersComponent,
   DataViewerFilterType,
   DataViewerStateService,
} from "src/app/shared/data-viewer";
import { DataViewerFiltersBuilder } from "src/app/shared/data-viewer/data-viewer-filters/data-viewer-filters.builder";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import type {
   RequestFilter,
   RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import type { EfficiencyRates } from "src/app/shared/services/insights/insights.model";
import { InsightsService } from "src/app/shared/services/insights/insights.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { TasksModalsService, TaskStatus } from "src/app/tasks/components/shared";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { ManageUser } from "src/app/users/services/manageUser";

Chart.register(
   ArcElement,
   CategoryScale,
   LinearScale,
   LineController,
   LineElement,
   PointElement,
   Tooltip,
);

type AssetDatum = {
   assetID: number;
   assetName: string;
   totalInvoiceCost: number;
   totalPartsCost: number;
   totalLaborCost: number;
   totalOperatingCost: number;
   roiInvoiceTasks: Array<any>;
   roiLaborTasks: Array<any>;
   roiPartsTasks: Array<any>;
   roiTotalTasks: Array<any>;
   locationName: string;
};

@Component({
   selector: "roi-panel",
   templateUrl: "./roi-panel.component.html",
   styleUrls: ["./roi-panel.component.scss"],
   standalone: true,
   providers: [DataViewerStateService],
   imports: [
      NgClass,
      PanelComponent,
      MinimalIconButtonComponent,
      LoadingAnimationComponent,
      CardComponent,
      DataViewerFiltersComponent,
      IconComponent,
      TooltipDirective,
      LimbleHtmlDirective,
      BetterDecimalPipe,
   ],
})
export class RoiPanelComponent implements OnInit, AfterViewInit {
   private readonly manageLang = inject(ManageLang);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly dashboardService = inject(DashboardService);
   private readonly chartJSHelper = inject(ChartJSHelper);
   private readonly themingService = inject(ThemingService);
   private readonly manageUser = inject(ManageUser);
   private readonly dataViewerStateService = inject(DataViewerStateService);
   private readonly virtualWidgetService = inject(VirtualWidgetService);
   private readonly lineBarGraphDisplayHandlerService = inject(
      LineBarGraphDisplayHandlerService,
   );
   private readonly tasksModalsService = inject(TasksModalsService);
   private readonly dashboardModalHelpersService = inject(DashboardModalHelpersService);
   private readonly managePO = inject(ManagePO);
   private readonly insightsService = inject(InsightsService);
   private readonly alertService = inject(AlertService);
   private readonly displayHandlerService: LineBarGraphDisplayHandler;

   dashboardReportingLimit = input<number | undefined>(undefined);

   @ViewChild(FilterSelectorComponent) filterSelector:
      | FilterSelectorComponent
      | undefined;
   public assets: Lookup<"assetID", Asset> = new Lookup("assetID");
   public currencySymbol: string | undefined;
   public activeFilters: Map<FilterType, Filter>;
   public totalOperatingCost: number;
   public totalLabor: number;
   public totalPartsCost: number;
   public totalInvoices: number;
   public assetsPage: number;
   public assetDataArray: Array<AssetDatum>;
   public loadingLegacyData: boolean;
   public readonly filterTypes: Set<FilterType>;
   public readonly assetsItemsPerPage = 9;
   private lineChart: Chart<"line"> | undefined;
   private readonly tasks: TaskLookup = new Lookup("checklistID");
   private readonly lineChartOptions: ChartOptions<"line">;
   private readonly readyToRender$: AsyncSubject<void>;
   private readonly graphByAttribute = "checklistCompletedDate" as const;
   private readonly currentEfficiencyRates: Signal<EfficiencyRates> =
      this.insightsService.getEfficiencyRates();
   private readonly virtualWidgetIds: VirtualWidgetID[] = [
      "downtimeHoursEfficiency",
      "laborCostEfficiency",
      "partCostEfficiency",
      "costOfPOsEfficiency",
      "downtimeHoursEfficiencyLine",
      "laborCostEfficiencyLine",
      "partCostEfficiencyLine",
      "costOfPOsEfficiencyLine",
   ];

   public isLoading = signal<boolean>(false);

   private readonly widgetResponse$ = combineLatest([
      this.dataViewerStateService.filters$,
      this.insightsService.checkForCustomEfficiencyRates$,
   ]).pipe(
      switchMap((filters: any) => {
         this.isLoading.set(true);

         return this.virtualWidgetService
            .fetchWidgetContents({
               widgetIds: this.virtualWidgetIds,
               filters: filters[0],
            })
            .pipe(
               finalize(() => {
                  this.isLoading.set(false);
               }),
            );
      }),
   );
   public widgetContents = toSignal(this.widgetResponse$);

   public widgetDefinitions = toSignal(
      this.virtualWidgetService.fetchWidgetDefinitions({
         widgetIds: this.virtualWidgetIds,
      }),
   );

   private readonly requestOptions = toSignal<Partial<RequestOptions<RequestFilter>>>(
      this.dataViewerStateService.requestOptions$,
   );

   public filters = signal<Array<DataViewerFilter>>([]);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public downtimeSavingsVal = computed(() => {
      const widgetIndex = this.virtualWidgetIds.indexOf("downtimeHoursEfficiency");
      const content = this.widgetContents()?.[widgetIndex];
      return this.insightsService.calcDowntimeSavings(
         (content as TileContent)?.value ?? 0,
      );
   });

   public laborSavingsVal = computed(() => {
      const widgetIndex = this.virtualWidgetIds.indexOf("laborCostEfficiency");
      const content = this.widgetContents()?.[widgetIndex];
      return this.insightsService.calcLaborSavings((content as TileContent)?.value ?? 0);
   });

   public partsSavingsVal = computed(() => {
      const widgetIndex = this.virtualWidgetIds.indexOf("partCostEfficiency");
      const content = this.widgetContents()?.[widgetIndex];
      return this.insightsService.calcPartsSpendSavings(
         (content as TileContent)?.value ?? 0,
      );
   });

   public posSavingsVal = computed(() => {
      const widgetIndex = this.virtualWidgetIds.indexOf("costOfPOsEfficiency");
      const content = this.widgetContents()?.[widgetIndex];
      return this.insightsService.calcPosSpendSavings(
         (content as TileContent)?.value ?? 0,
      );
   });

   private readonly lineGraphWidgetContent: Signal<WidgetContent | undefined> = computed(
      () => {
         return this.buildLineGraphWidgetContent();
      },
   );

   public constructor() {
      this.displayHandlerService = this.getDisplayHandler();
      // this.insightsService.checkForCustomEfficiencyRates();

      Chart.defaults.color = this.themingService.getThemedGraphFontColor();
      Chart.defaults.font.family = "Clarity City";
      this.loadingLegacyData = true;
      this.activeFilters = new Map();
      this.filterTypes = new Set(["location", "dateRange"]);
      this.totalOperatingCost = 0;
      this.totalLabor = 0;
      this.totalPartsCost = 0;
      this.totalInvoices = 0;
      this.assetsPage = 1;
      this.assetDataArray = [];
      this.readyToRender$ = new AsyncSubject();
      this.readyToRender$.next();

      this.lineChartOptions = this.buildLineChartOptions();

      effect(() => {
         if (this.lineGraphWidgetContent()) {
            const lineGraphData = this.generateLineGraphData();
            if (lineGraphData) {
               this.renderLineChart(lineGraphData);
            }
         }
      });

      effect(() => {
         if (this.currentEfficiencyRates()) {
            const lineGraphData = this.generateLineGraphData();
            if (lineGraphData) {
               this.renderLineChart(lineGraphData);
            }
         }
      });

      this.manageUser.currentUserInitialized$.subscribe((currentUser) => {
         assert(currentUser !== undefined);
         if (currentUser.currency !== undefined) {
            this.currencySymbol = currentUser.currency.symbol;
         }
      });
   }

   public ngOnInit() {
      const filtersBuilder = new DataViewerFiltersBuilder();
      const filters = filtersBuilder
         .addDate({
            labelKey: "DateRange",
            key: "completed",
            value: this.dashboardService.getDefaultDateRangeForPanels(
               this.dashboardReportingLimit(),
            ),
         })
         .addLocationFilter()
         .build();
      this.filters.set(filters);
   }

   public ngAfterViewInit() {
      this.readyToRender$.complete();
   }

   private buildLineGraphWidgetContent(): LineBarGraphContent {
      const downTimewidgetIndex = this.virtualWidgetIds.indexOf(
         "downtimeHoursEfficiencyLine",
      );
      const laborWidgetIndex = this.virtualWidgetIds.indexOf("laborCostEfficiencyLine");
      const partsWidgetIndex = this.virtualWidgetIds.indexOf("partCostEfficiencyLine");
      const invoicesWidgetIndex = this.virtualWidgetIds.indexOf(
         "costOfPOsEfficiencyLine",
      );
      const laborWidgetContent = this.widgetContents()?.[
         laborWidgetIndex
      ] as LineBarGraphContent;
      const partsWidgetContent = this.widgetContents()?.[
         partsWidgetIndex
      ] as LineBarGraphContent;
      const invoicesWidgetContent = this.widgetContents()?.[
         invoicesWidgetIndex
      ] as LineBarGraphContent;
      const downtimeWidgetContent = this.widgetContents()?.[
         downTimewidgetIndex
      ] as LineBarGraphContent;
      const lineGraphWidgetContent = {
         labels: downtimeWidgetContent.labels,
         datasets: [] as Array<Array<ListBarGraphContentElement>>,
         labelSplitBy: downtimeWidgetContent.labelSplitBy,
         source: downtimeWidgetContent.source,
      };
      lineGraphWidgetContent.labelSplitBy = [
         this.lang().Downtime,
         this.lang().Labor,
         this.lang().Parts,
         this.lang().POs,
      ];
      lineGraphWidgetContent.datasets[0] = downtimeWidgetContent.datasets[0].map(
         (dataset) => {
            return {
               ...dataset,
               value: this.insightsService.calcDowntimeSavings(dataset.value),
            };
         },
      );
      lineGraphWidgetContent.datasets[1] = laborWidgetContent.datasets[0].map(
         (dataset) => {
            return {
               ...dataset,
               value: this.insightsService.calcLaborSavings(dataset.value),
            };
         },
      );
      lineGraphWidgetContent.datasets[2] = partsWidgetContent.datasets[0].map(
         (dataset) => {
            return {
               ...dataset,
               value: this.insightsService.calcPartsSpendSavings(dataset.value),
            };
         },
      );
      lineGraphWidgetContent.datasets[3] = invoicesWidgetContent.datasets[0].map(
         (dataset) => {
            return {
               ...dataset,
               value: this.insightsService.calcPosSpendSavings(dataset.value),
            };
         },
      );
      return lineGraphWidgetContent;
   }

   public filterChangeHandler(updatedFilters: Map<FilterType, Filter>) {
      this.activeFilters = updatedFilters;
   }

   private getInitialLineData(): ChartData<"line"> {
      const defaults = {
         tension: 0.5,
         pointRadius: 5,
         fill: false,
         xAxisID: "xAxes",
         yAxisID: "yAxes",
         data: [],
      };
      return {
         labels: [],
         datasets: [
            {
               label: this.lang().Downtime,
               borderColor: "#e62737",
               pointBackgroundColor: "#e62737",
               ...defaults,
            },
            {
               label: this.lang().Labor,
               borderColor: "#289e49",
               pointBackgroundColor: "#289e49",
               ...defaults,
            },
            {
               label: this.lang().Parts,
               borderColor: "#5083d5",
               pointBackgroundColor: "#5083d5",
               ...defaults,
            },
            {
               label: this.lang().POs,
               borderColor: "#f29422",
               pointBackgroundColor: "#f29422",
               ...defaults,
            },
         ],
      };
   }

   private renderLineChart(lineData: ChartData<"line">): void {
      if (this.lineChart !== undefined) {
         this.lineChart.destroy();
      }
      const ctx2 = document.getElementById("roi-line-chart");
      if (ctx2 instanceof HTMLCanvasElement) {
         this.lineChart = new Chart(ctx2, {
            type: "line",
            data: lineData,
            options: this.lineChartOptions,
         });
      }
   }

   public viewLineItem(event: Event): void {
      if (this.lineChart === undefined) {
         throw new Error("roiLineChart is not defined");
      }
      //Doing a cast here because the type library is not accurate. My type assertion may not be 100% accurate either.
      const activePoints = this.lineChart.getElementsAtEventForMode(
         event,
         "nearest",
         { intersect: true },
         false,
      );
      if (activePoints.length === 0) return;
      const clickedDatasetIndex = activePoints[0].datasetIndex;
      const clickedIndex = activePoints[0].index;
      const label = String(this.lineChart.data.labels?.[clickedIndex] ?? "");
      const line = this.lineChart.data.datasets[clickedDatasetIndex].label;
      let title = "";
      let widgetIndex;

      switch (line) {
         case this.lang().Downtime:
            widgetIndex = this.virtualWidgetIds.indexOf("downtimeHoursEfficiencyLine");
            title = `${this.lang().Downtime} - ${label}`;
            break;
         case this.lang().Labor:
            widgetIndex = this.virtualWidgetIds.indexOf("laborCostEfficiencyLine");
            title = `${this.lang().Labor} - ${label}`;
            break;
         case this.lang().Parts:
            widgetIndex = this.virtualWidgetIds.indexOf("partCostEfficiencyLine");
            title = `${this.lang().Parts} - ${label}`;
            break;
         case this.lang().POs:
            widgetIndex = this.virtualWidgetIds.indexOf("costOfPOsEfficiencyLine");
            title = `${this.lang().POs} - ${label}`;
            break;
         default:
            return;
      }

      const monthStart = moment(label, "MMM YYYY").startOf("month");
      const monthEnd = monthStart.clone().endOf("month");

      const widgetDefinitions = this.widgetDefinitions();
      if (widgetDefinitions) {
         const widgetDef = widgetDefinitions[widgetIndex];
         const extraFilters = this.tasksModalsService.formatToExtraFilters(
            {
               ...this.requestOptions(),
               filters: {
                  ...this.requestOptions()?.filters,
                  completedStart: monthStart.toISOString(),
                  completedEnd: monthEnd.toISOString(),
               },
            },
            [TaskStatus.Closed],
         );
         if (line === this.lang().POs) {
            // const poIDs: Array<number> = this.lineChart.data.datasets[clickedDatasetIndex].data;
            const POContents = this.widgetContents()?.[widgetIndex] as any;
            const poIDs = POContents?.datasets[0]?.[clickedIndex]?.customData?.poIDs;
            this.openPOsModal(title, poIDs);
         } else {
            this.tasksModalsService.openForWidget(widgetDef, extraFilters, undefined, {
               title: title,
               onlyExportVisibleData: true,
               hideBulkPrintButton: true,
            });
         }
      }
   }

   public viewTileWidgetItems(widgetID: VirtualWidgetID) {
      const widgetIndex = this.virtualWidgetIds.indexOf(widgetID);
      const widgetDefinitions = this.widgetDefinitions();
      if (!widgetDefinitions) {
         return;
      }
      const widgetDef = widgetDefinitions[widgetIndex];
      if (widgetID === "costOfPOsEfficiency") {
         const poContents = this.widgetContents()?.[widgetIndex] as any;
         const poIDs = poContents?.poIDs;
         const title = this.lang().POSpendEfficiencySavings;
         this.openPOsModal(title, poIDs);
         return;
      }
      const extraFilters = this.tasksModalsService.formatToExtraFilters(
         this.requestOptions() ?? {},
         [TaskStatus.Closed],
      );

      this.tasksModalsService.openForWidget(widgetDef, extraFilters, undefined, {
         onlyExportVisibleData: true,
         hideBulkPrintButton: true,
      });
   }

   private getDateRange() {
      return this.dashboardService.getDateRange(
         this.tasks,
         this.graphByAttribute,
         this.activeFilters,
      );
   }

   private createCustomLineGraphTooltip(
      chart: Chart<any>,
      tooltipModel: TooltipModel<any>,
   ) {
      const currencySymbol = this.manageUser.getCurrentUser()?.currency?.symbol ?? "";
      const dataIndex = tooltipModel.dataPoints[0].dataIndex;
      const tooltipItems =
         chart.config.data.datasets.map((series) => {
            return {
               text: `${series?.label}: ${currencySymbol}${series?.data?.[dataIndex]}`,
               color: String(series.borderColor),
            };
         }) ?? [];
      this.chartJSHelper.createCustomTooltip(chart, tooltipModel, tooltipItems);
   }

   private generateLineGraphData(): ChartData<"line"> | undefined {
      // Check for undefined values
      if (!this.lineGraphWidgetContent()) {
         return undefined;
      }

      // Get the separate JS data
      const roiMonthlyLineGraphData =
         this.displayHandlerService.convertToChartJSStructure(
            this.lineGraphWidgetContent() as LineBarGraphContent,
         );

      const chartData = this.getInitialLineData();
      assert(chartData.labels !== undefined && chartData.labels.length === 0);
      assert(chartData.datasets !== undefined);
      const lineDatasets = roiMonthlyLineGraphData.datasets.map((dataset) => {
         return dataset.dataset as ChartDataset<"line">;
      });
      chartData.datasets[0].data = lineDatasets[0].data;
      chartData.datasets[1].data = lineDatasets[1].data;
      chartData.datasets[2].data = lineDatasets[2].data;
      chartData.datasets[3].data = lineDatasets[3].data;

      // The labels from all data sets will be the same, so we can just use the labels from one of them.
      chartData.labels = roiMonthlyLineGraphData.labels;
      return chartData;
   }

   private buildLineChartOptions(): ChartOptions<"line"> {
      return {
         responsive: true,
         plugins: {
            legend: {
               display: false,
            },
            tooltip: {
               mode: "index",
               position: "nearest",
               intersect: false,
               enabled: true,
               titleFont: {
                  size: 14,
               },
               bodyFont: {
                  size: 14,
               },
               external: (context) => {
                  if (isMobile()) {
                     this.createCustomLineGraphTooltip(context.chart, context.tooltip);
                  }
               },
            },
         },
         scales: {
            xAxes: {
               type: "category",
               grid: {
                  color: (ctx) => this.themingService.getThemedGridLineColor(ctx.index),
                  lineWidth: 0.75,
               },
            },
            yAxes: {
               type: "linear",
               grid: {
                  color: (ctx) => this.themingService.getThemedGridLineColor(ctx.index),
                  lineWidth: 0.75,
               },
            },
         },
         aspectRatio: 4,
         onHover: (event, _chartElement, chart) => {
            this.chartJSHelper.onHoverHandler(event, chart);
         },
      };
   }

   private getDisplayHandler(): LineBarGraphDisplayHandler {
      const fakeWidgetDef: WidgetDefinition = {
         ...getDefaultEnglishWidgetDef(),
         type: "tasks",
         viewedAs: "lineGraph",
         display: {
            view: "downtimeHours",
            segment: "monthly",
         },
      };

      return this.lineBarGraphDisplayHandlerService.getHandler(fakeWidgetDef);
   }

   protected async onSetFilter(dataViewerFilter: DataViewerFilter) {
      if (dataViewerFilter.type === DataViewerFilterType.DATE) {
         const selectedDateRange = dataViewerFilter.value as {
            completedStart: Date;
            completedEnd?: Date;
         };
         const adjustedDate = this.dashboardService.calculateDateRangeParams(
            this.dashboardReportingLimit(),
            {
               startDate: selectedDateRange.completedStart,
               endDate: selectedDateRange.completedEnd ?? new Date(),
            },
         );
         dataViewerFilter.value = {
            completedStart: adjustedDate.startDate,
            completedEnd: adjustedDate.endDate,
         };
      }
      await this.dataViewerStateService.addFilter(dataViewerFilter);
   }

   protected async onRemoveFilter(dataViewerFilter: DataViewerFilter) {
      await this.dataViewerStateService.removeFilter(dataViewerFilter);
   }

   public async openROISettingsModal() {
      const modalRef = this.modalService.open(RoiSettings);
      const result = await modalRef.result;
      if (result === true) {
         this.alertService.addAlert("ROI settings saved", "success", 5000);
      }
   }

   private openPOsModal(title: string, ids: Array<number>): void {
      const poObjs = this.setPOModalInfo(ids);
      const { modalColumns, modalOptions } =
         this.dashboardModalHelpersService.prepPOsModalData("POSpendEfficiencySavings");

      const instance = this.modalService.open(ViewListModal);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: title,
            data: false,
            locationID: 0,
            viewingAsset: 0,
            sortBind: "-checklistName",
            modalTitle: title,
            objs: poObjs,
            options: modalOptions,
            columns: modalColumns,
         },
      };
   }

   private setPOModalInfo(ids: Array<number>): any {
      // ids are poIDs in the case of costOfPOs & numOfPOs, but poItemIDs in the case of numOfPOItems
      const rate = this.currentEfficiencyRates().poSpendEfficiencyRate;
      return ids.map((id) => {
         const total = this.managePO.getPurchaseOrderCostTotals(id)?.total;
         const poDate = this.managePO.getPurchaseOrder(id)?.date;
         const efficiency = total ? this.insightsService.calcPosSpendSavings(total) : 0;
         return {
            poID: id,
            poNumber: this.managePO.getPurchaseOrder(id)?.poNumber,
            poNumberForDisplay:
               this.managePO.getPurchaseOrderNumberForDisplay(id)?.poNumberForDisplay,
            currentStatus: this.managePO.getPurchaseOrderCurrentState(id)?.name,
            date: poDate,
            total: total,
            efficiencyRate: rate,
            efficiencySavings: efficiency,
         };
      });
   }

   public openCommunity() {
      window.open("https://community.limble.com/", "_blank")?.focus();
   }

   public openTraining() {
      window
         .open(
            "https://help.limblecmms.com/en/articles/8589196-upcoming-live-training-schedule",
            "_blank",
         )
         ?.focus();
   }

   public openLearnMore() {
      window.open("", "_blank")?.focus();
   }
}
