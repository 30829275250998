import type { Signal } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   BasicModalHeaderComponent,
   DangerButtonComponent,
   IconComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   PanelComponent,
   TooltipDirective,
   OutlinedButtonComponent,
   PrimaryButtonComponent,
   MinimalIconButtonComponent,
   LimUiModalRef,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { ConfirmModalComponent } from "src/app/lite/ui/confirm-modal/confirm-modal.component";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { PartUnitOfMeasurePipe } from "src/app/shared/pipes/partUnitOfMeasure.pipe";
import type { EfficiencyRates } from "src/app/shared/services/insights/insights.model";
import { InsightsService } from "src/app/shared/services/insights/insights.service";

@Component({
   selector: "roi-settings",
   templateUrl: "./roi-settings-modal.component.html",
   styleUrls: ["./roi-settings-modal.component.scss"],
   standalone: true,
   imports: [
      TooltipDirective,
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      OutlinedButtonComponent,
      PrimaryButtonComponent,
      ModalBodyComponent,
      PanelComponent,
      IconComponent,
      FormsModule,
      ModalFooterComponent,
      DangerButtonComponent,
      BetterDatePipe,
      PartUnitOfMeasurePipe,
      MinimalIconButtonComponent,
   ],
})
export class RoiSettings {
   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);
   public readonly modalRef: LimUiModalRef<ConfirmModalComponent, boolean> =
      inject(LimUiModalRef);

   private readonly manageLang = inject(ManageLang);
   private readonly insightsService = inject(InsightsService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   private readonly efficiencyRates: Signal<EfficiencyRates> =
      this.insightsService.getEfficiencyRates();
   private readonly defaultEfficiencyRates: EfficiencyRates =
      this.insightsService.getDefaultEfficiencyRates();
   public averageDowntimeCost = this.efficiencyRates().averageDowntimeCost;
   public downTimeEfficiencyRate = this.efficiencyRates().downtimeEfficiencyRate;
   public laborEfficiencyRate = this.efficiencyRates().laborEfficiencyRate;
   public partSpendEfficiencyRate = this.efficiencyRates().partSpendEfficiencyRate;
   public poSpendEfficiencyRate = this.efficiencyRates().poSpendEfficiencyRate;

   public resetToDefault(
      type: "downtimeRate" | "downtimeCost" | "laborRate" | "partsRate" | "posRate",
   ) {
      switch (type) {
         case "downtimeRate":
            this.downTimeEfficiencyRate =
               this.defaultEfficiencyRates.downtimeEfficiencyRate;
            break;
         case "downtimeCost":
            this.averageDowntimeCost = this.defaultEfficiencyRates.averageDowntimeCost;
            break;
         case "laborRate":
            this.laborEfficiencyRate = this.defaultEfficiencyRates.laborEfficiencyRate;
            break;
         case "partsRate":
            this.partSpendEfficiencyRate =
               this.defaultEfficiencyRates.partSpendEfficiencyRate;
            break;
         case "posRate":
            this.poSpendEfficiencyRate =
               this.defaultEfficiencyRates.poSpendEfficiencyRate;
            break;
         default:
            break;
      }
   }

   public close() {
      this.modalRef.close(false);
   }

   public async submit() {
      await this.insightsService.saveEfficiencyRates({
         downtimeEfficiencyRate: this.downTimeEfficiencyRate,
         averageDowntimeCost: this.averageDowntimeCost,
         laborEfficiencyRate: this.laborEfficiencyRate,
         partSpendEfficiencyRate: this.partSpendEfficiencyRate,
         poSpendEfficiencyRate: this.poSpendEfficiencyRate,
      });
      this.modalRef.close(true);
   }
}
